.formHeading {
  background-color: #eeeeee;
  color: #26568e;
  padding: 4px;
}

.subHeading {
  background-color: #eeeeee;
  color: #26568e;
  padding: 4px;
  width: 100%;
}
.header{
  overflow-y: auto;
  height: 100px;
}

.formlabel {
  color: black;
  font-weight: bold;
}
tr:nth-child(even) {
  background-color: #f5f5f5;
}

.ck-editor__editable {
  min-height: 250px;
}
label.required::after {
  content: "*";
  color: #ea002a;
}
.success-toast{
background: #4aa252 !important;
}
.warning-toast{
background: #ff9400 !important;
}
.error-toast{
background: #c4392a !important;
}

.infoDiv{
   background-color: #eeeeee;
   padding: 10px;
}
.infoTypeLbl{
  font-size: 18px;
  font-weight: 600;
}
.infoTypeContent{
  font-size: 15px;
}

/* View Ticket Css Starts */

.subject{
  text-align: left;
  font-weight: 400;
  text-decoration: underline;
  padding-bottom: 20px;
  padding-top: 10px;
}
.lblFields{
  color: black;
  font-weight: bold;
  font-size: 16px;
}
.lblFieldValue{
  color: black;
  font-size: 16px;
}
.fa-trash-o{
  font-size: 26px;
  cursor: pointer;
}
.lblFileName{
  font-size: 12px;
  color: black;
  padding: 10px;
}
.labelFileUpload {
  background-color: #26568e;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}
.fa-hand-o-right{
   color: #26568e;
   font-size: 20px;
}

.feedbackButtons{
  border-radius: 25px;
  background-color: #73AD21;
  padding: 10px;
  text-align: center;
  width: 100px;
  height: 45px;
  cursor: pointer;
}
.lblFeedbackForm{
  font-weight: bolder;
  border-radius: 25px;
  padding: 10px;
  text-align: center;
  width: 100px;
  height: 45px;
  cursor: pointer;
}
.triangle {
  position: relative;
  margin-top: 15px;
  /* margin: 3em; */
  padding-left: 10px;
  box-sizing: border-box;
  /* background: #fff; */
  /* border: 1px solid #fafafa;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4); */
}
.triangleMain{
border-radius: 10px;
margin-top: 15px;
border: 1px solid #fafafa;
box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
margin-bottom: 15px;
background-color: #fffff0;
}
.triangleInnerContent{
  padding-left: 10px;
}
.triangle::after{
    z-index: -10;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0;
    bottom: 0;
    top: calc(20% - 5px);
    left:0;
    box-sizing: border-box;
    
    border: 5px solid #fff;
    border-color: transparent transparent #fff #fff;
    
    transform-origin: 0 0;
    transform: rotate(45deg);
    
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
  }
.triangle::before{
    z-index: 10;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0;
    bottom: 0;
    top: calc(20% - 5px);
    left:0;
    box-sizing: border-box;
    
    border: 5px solid black;
    border-color: transparent transparent #fff #fff;
    
    transform-origin: 0 0;
    transform: rotate(45deg);
  }
.userIcon{
  margin-top: 15px;
} 
.breadcrumb{
  background-color: white !important;
}
.breadcrumb-item{
  background-color: white !important;
} 
.viewFile{
  height: 500px;
  width: 100%;
  max-width: 100%;
  max-height: 500px;
  cursor: zoom-out;
}

/**Start--Material Tables theme*/
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon{
  color: orange!important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active{
  color: #FFD800!important;
}
/**End--Material Tables theme*/